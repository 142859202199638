import { AccessRights } from "../api.typings";

export interface TeamMember {
  name: string;
  givenName: string;
  internalHrTeam: string;
  id: string;
};

export interface TeamPermissionsDTO {
  permissions: {
    name: string
  }[];
  defaultVisibilityTeams: {id: string} [];
};

export type TeamPermissions = TeamPermissionsDTO;

export type TeamReasonType = "main" | "custom" | "implicit";
export type TeamReasonTypeDTO = "Main" | "Custom" | "Implicit";

export const TeamReason: { [key in TeamReasonType]: TeamReasonTypeDTO } = {
  main: "Main",
  custom: "Custom",
  implicit: "Implicit"
};

export interface TeamPermission {
  permission: string;
}

export interface TeamSettingsDTO {
  permissions: TeamPermission[];
  objectAccessRights: AccessRights;
}

export interface TeamNode {
  id: string;
  name: string;
  path: string;
  expanded: boolean;
  selected: boolean;
  hidden: boolean;
  parent?: TeamNode;
  children: TeamNode[];
  sphere?: string;
  fullName?: string;
}