import { find, includes, map, orderBy, reduce, replace, uniqBy, values } from "lodash-es";
import {
  BusinessFunctionFilter,
  BusinessFunctionOptionType,
  BusinessFunctionRole,
  BusinessFunctionRoleItem,
  Mandate,
  MandateFormModel,
  MandatePayload,
  SavedMandate,
} from "../../common/typings/mandate.typing";
import {
  AccessRightsDto,
  BankAccountDTO,
  College,
  CollegeDTO,
  MandateCreateError,
  MandateDTO,
  MandateSaveDTO,
} from "./mandate.typings";
import { isStringEmpty } from "../../utils/strings/stringUtils";
import { PhoneType } from "../../ic-mandate-creator/form/AddPhonesForm/contants";
import { ContactId } from "../../common/typings/contacts.typings";
import { AccountNumberDTO } from "@ic-anywhere/ic-dal";
import { AccessRights } from "../api.typings";
import { TeamReason } from "../teams/teams.typings";

const mapCollegeDTOToCollege = ({ name, account, attorneys, id }: CollegeDTO): College => ({
  name,
  account,
  attorneys,
  id,
  displayName: `${name} ${account.name} ${account.level ? `(${account.level})` : ""}`,
});

export const mapCollegesDTOToColleges = (colleges: CollegeDTO[]): College[] => colleges?.map(mapCollegeDTOToCollege);

export const mapMandateList = (mandates: MandateDTO[]): Mandate[] => {
  return map(mandates, mandate => ({
    id: mandate.id,
    contact: {
      id: mandate.contact?.id ? mandate.contact?.id : mandate.person?.id ?? mandate.college.id,
      type: mandate.contact?.id ? "contact" : mandate.person?.id ? "person" : "college",
      fullName: mandate.contact?.friendlyName
        ? mandate.contact?.friendlyName
        : mandate.person?.friendlyName ?? mandate.college.name,
      email: mandate.contact?.mainEmail,
    },
    delegationId: mandate.delegation?.id,
    businessFunction: {
      ...mandate.businessFunction,
      friendlyName: replace(mandate.businessFunction.friendlyName ?? "", /\//g, " > "),
      options: map(mandate.businessFunction?.options ?? [], value => value.toLowerCase() as BusinessFunctionOptionType),
    },
    role: { ...mandate.role, roleItemId: mandate.roleItemId },
    account: mandate.account,
    jobTitle: mandate.jobTitle,
    isActive: mandate.isActive,
    startDate: mandate.startDate,
    endDate: mandate.endDate,
  }));
};

export const mapToAccessRightDTO = ({ visibility, viewers, owners }: AccessRights): AccessRightsDto => ({
  visibility,
  owners:
    visibility !== "public"
      ? { teams: owners.teams.filter(x => x.reason !== TeamReason.implicit).map(({ id, reason }) => ({ id, reason })) }
      : undefined,
  viewers:
    visibility !== "public"
      ? { teams: viewers.teams.filter(x => x.reason !== TeamReason.implicit).map(({ id }) => ({ id })) }
      : undefined,
});

export const mapToMandateSaveDTO = (
  form: MandatePayload,
  contact: ContactId,
  bankAccount?: BankAccountDTO
): MandateSaveDTO => {
  return {
    mandatedContact: { id: contact?.id, type: contact?.type },
    bdrId: form.thirdParty?.bdrId,
    level: form.thirdParty?.level,
    roleItemId: form.roleItemId ?? undefined,
    applyInheritanceOnAccount: form.inheritance,
    jobTitle: isStringEmpty(form?.jobTitle) ? undefined : form.jobTitle?.trim(),
    countryIso3: undefined,
    delegationId: form.delegationId ?? undefined,
    startDate: form.startDate ?? undefined,
    endDate: form.endDate ?? undefined,
    phones: map(form.phones, ({ type, number }) => ({
      type: type as PhoneType,
      value: `${number}`,
    })),
    uboType: form.uboType ?? undefined,
    contractId: isStringEmpty(form?.contractId) ? undefined : form.contractId?.trim(),
    bankAccount: bankAccount,
    accessRights: form.accessRights ? mapToAccessRightDTO(form.accessRights) : undefined,
  };
};

export const mapTobankAccountDTO = (isAllBankAccount = false, bankAccount?: AccountNumberDTO): BankAccountDTO => {
  return {
    all: isAllBankAccount,
    id: bankAccount
      ? {
          iban: bankAccount.accountNumberType === "IBAN" ? bankAccount.accountNumberValue : undefined,
          bban: bankAccount.accountNumberType === "BBAN" ? bankAccount.accountNumberValue : undefined,
          bic: bankAccount.accountBic,
        }
      : undefined,
  };
};

export const mapToSavedMandate = (id: string, form: MandatePayload, contact: ContactId): SavedMandate => {
  return {
    id: id,
    delegationId: form.delegationId,
    thirdParty: form.thirdParty,
    contact: contact,
    inheritance: form.inheritance,
    jobTitle: form.jobTitle,
    functionRole: {
      roleItemId: form.roleItemId,
      function: {
        id: form.businessFunction?.id ?? "",
        name: form.businessFunction?.name ?? "",
      },
      role: {
        id: form.businessFunctionRole?.id ?? "",
        name: form.businessFunctionRole?.name ?? "",
      },
    },
    startDate: null,
    endDate: null,
    uboType: form.uboType,
    contractId: form.contractId,
    accessRights: form.accessRights,
  };
};

export const mapToBusinessFunctionFilter = (items: BusinessFunctionRoleItem[]): BusinessFunctionFilter[] => {
  const businessFunctionFilter = reduce<BusinessFunctionRoleItem, { [key: string]: BusinessFunctionFilter }>(
    items,
    (acc, item) => {
      const newRoles = { ...item.role, roleItemId: item.id };
      if (acc[item.businessFunction.id]) {
        const roles = acc[item.businessFunction.id].roles;
        roles.push(newRoles);
        acc[item.businessFunction.id].roles = uniqBy(roles, "id");
      } else {
        acc[item.businessFunction.id] = {
          roles: [newRoles],
          ...item.businessFunction,
          options: map(
            item.businessFunction?.options ?? [],
            value => value.toLowerCase() as BusinessFunctionOptionType
          ),
          friendlyName: replace(item.businessFunction.friendlyName ?? "", /\//g, " > "),
          isAssignable: item.isAssignable,
        };
      }
      return acc;
    },
    {}
  );
  return orderBy(values(businessFunctionFilter), ["isAssignable", "friendlyName"], ["desc", "asc"]);
};

export const hasBusinessFunctionTypeOption = (
  options: BusinessFunctionOptionType[],
  toCheck: BusinessFunctionOptionType
): boolean => includes(options, toCheck);

export const findBusinessFunctionFilterById = (
  items: BusinessFunctionFilter[],
  roleItemId: string
): { bf: BusinessFunctionFilter; role: BusinessFunctionRole } | null => {
  const findRole = (roles): BusinessFunctionRole => find(roles, r => r.roleItemId === roleItemId);
  const bf = find(items, bf => !!findRole(bf.roles) !== undefined);
  if (bf) {
    return { bf, role: findRole(bf.roles) };
  }
  return null;
};

export const mapToMandateCreateError = (
  error: any,
  form: MandateFormModel,
  contact: ContactId,
  bankAccount: BankAccountDTO | undefined
): MandateCreateError => ({
  statusCode: error?.status,
  statusMessage: error?.userMessage || error?.message,
  sourceMandate: mapToMandateSaveDTO(form, contact, bankAccount),
  missingPersonMandatoryFields: error?.missingPersonMandatoryFields,
});
