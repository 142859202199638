import * as React from "react";
import { IntlProvider } from "react-intl";
import * as fr from "../../../../locales/labels.fr.json";
import * as en from "../../../../locales/labels.en.json";

export const supportedLanguages = ["en", "fr"] as const;
export type SupportedLanguage = typeof supportedLanguages[number];

export interface Props {
  language?: string;
  children?: any;
}
const AppIntlProvider: React.FC<Props> = ({ language = "en", children }) => {
  // all translated massages
  const messages: Record<SupportedLanguage, Record<string, any>> = {
    fr,
    en,
  };

  return (
    <IntlProvider messages={messages[language].default} locale={language} defaultLocale={language}>
      {children}
    </IntlProvider>
  );
};

export default AppIntlProvider;
