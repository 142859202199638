import * as React from "react";

interface Props {
  isLoading: boolean;
  message?: string;
}

const Loading: React.FC<Props> = ({ isLoading, message = "Loading..." }) =>
  isLoading ? <div className="spinner-grow">{message}</div> : null;

export default Loading;
