import * as React from "react";

interface Props {
  icon: string;
  title: string;
  message?: string;
  errorMessage?: string;
  contactSupport?: boolean;
}

const UserMessage: React.FC<Props> = ({ errorMessage, message, title, icon, contactSupport = false }: Props) => {
  return (
    <div className="spacing-m-3">
      <div className={"container text-center text-secondary"} style={{ maxWidth: "500px" }}>
        <i className="icon icon-xl d-inline-block">{icon}</i>
        <h4>{title}</h4>
        <div>{message}</div>
        {errorMessage && <div className="text-small">Error message: {errorMessage}</div>}
        {contactSupport && (
          <div>
            <a className="btn btn-link" href="mailto:WORLD-MAILTO-UNITY@socgen.com">
              contact support
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserMessage;
