import * as React from "react";
import { widgetize, WidgetPropsMapping } from "@sg-widgets/react-core";
import WidgetTracker from "../common/components/WidgetTracker";
import { WithSGB4 } from "../common/components/WithSGB4";
import MandateList from "./MandateList";
import { ContactId } from "../common/typings/contacts.typings";
import { ThirdId } from "../api/maestro/maestro.typings";

import { ContactPerimeterType, MandateAccount, MandateContact, MandateListColumnType } from "../common/typings/mandate.typing";
import AppIntlProvider from "../common/components/intl/AppInltProvider";
import { PropMapping } from "@sg-widgets/react-core/lib/props";
import { noop } from "lodash-es";

const TAG = "ic-mandate-list";

export interface Props {
  id?: string;
  contactId: ContactId;
  thirdId: ThirdId;
  accountId: string;
  contactPerimeter: ContactPerimeterType;
  roleId?: string;
  delegationId?: string;
  contactEmail?: string;
  functionId?: string;
  hiddenColumns: MandateListColumnType[];
  language?: string;
  onReady?: () => void;
  onContactClick?: (contact: MandateContact) => void;
  onThirdClick?: (account: MandateAccount) => void;
}

export const MandateListWidget: React.FC<Props> = ({
  id,
  contactId,
  thirdId,
  accountId,
  contactPerimeter,
  functionId,
  roleId,
  delegationId,
  contactEmail,
  hiddenColumns,
  language,
  onReady,
  onContactClick = noop,
  onThirdClick = noop,
}: Props) => {
  return (
    <AppIntlProvider language={language}>
      <WithSGB4>
        <MandateList
          id={id}
          contactId={contactId}
          thirdId={thirdId}
          functionId={functionId}
          accountId={accountId}
          contactPerimeter={contactPerimeter}
          delegationId={delegationId}
          contactEmail={contactEmail}
          roleId={roleId}
          onReady={onReady}
          onContactClick={onContactClick}
          onThirdClick={onThirdClick}
          hiddenColumns={hiddenColumns}
        />
        <WidgetTracker widgetName={TAG} />
      </WithSGB4>
    </AppIntlProvider>
  );
};

widgetize(
  TAG,
  MandateListWidget,
  {
    id: WidgetPropsMapping.asString(),
    contactId: WidgetPropsMapping.asObject(),
    contactEmail: WidgetPropsMapping.asString(),
    thirdId: WidgetPropsMapping.asObject(),
    accountId: WidgetPropsMapping.asString(),
    contactPerimeter: WidgetPropsMapping.asString()  as PropMapping<Props, ContactPerimeterType>,
    functionId: WidgetPropsMapping.asString(),
    roleId: WidgetPropsMapping.asString(),
    delegationId: WidgetPropsMapping.asString(),
    hiddenColumns: WidgetPropsMapping.asObject(),
    language: WidgetPropsMapping.asString(),
    onReady: WidgetPropsMapping.asEventEmitter("ready"),
    onContactClick: WidgetPropsMapping.asEventEmitter("contact-clicked"),
    onThirdClick: WidgetPropsMapping.asEventEmitter("third-clicked"),
  },
  { neverInjectGlobalCss: true }
);
